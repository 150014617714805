<template>

  <div id="app">
     <b-navbar type="is-black">
        <template slot="brand">
            <b-navbar-item>
               <img
                    src="./assets/logo.png"
                >
            </b-navbar-item>
        </template>
        <template slot="start">
            <b-navbar-item href="#">
                Amongowy Notes
            </b-navbar-item>
        </template>
    </b-navbar>
    <section class="hero is-black">
      <AddForm />
      <hr>
  <div class="hero-body">
    <div class="container">
      <h1 class="title">
         <Entries />
      </h1>
     
    </div>
  </div>
</section>
   
  </div>
</template>

<script>
import Entries from './components/Entries.vue'
import AddForm from './components/AddForm.vue'
export default {
  name: 'App',
  components: {
    Entries,AddForm
  }
}
</script>

<style>
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(114, 114, 114) !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgb(114, 114, 114) !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color:  rgb(114, 114, 114) !important;
}
.hero
{
  
  background-color:transparent !important;
}
.hero-body {
padding:0 !important;
}
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.navbar-item
{
  font-family: 'Amatic SC';
  font-size:1.6rem;
}
</style>
