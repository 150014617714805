<template>
  <div>
    <section>
      <b-field label="Dodawanie nowego cytatu">
        <b-input
          size="is-medium"
          class="textinput"
          v-model="said"
          placeholder="Kto powiedział"
          icon
          expanded
          custom-class="authorfield"
        ></b-input>
        <b-input
          size="is-medium"
          class="textinput"
          v-model="msg"
          placeholder="Tekst cytatu"
          icon
        ></b-input>
        <b-button type="is-dark" size="is-medium" @click="dodaj"
          >Dodaj Cytat</b-button
        >
      </b-field>
    </section>
  </div>
</template>

<script>
import Vuex from 'vuex'
export default {
  name: "AddForm",
  data() {
    return {
      msg: "",
      said: ''
    };
  },
  computed: {
    ...Vuex.mapState(["base_url"]),
  },
  methods: {
    dodaj() {
      if (this.msg.length < 1) {
        this.$buefy.toast.open({
          message: "Nie można wstawić pustego cytatu",
          type: "is-danger",
        });
        return;
      }
      if (this.said.length < 1) {
        this.$buefy.toast.open({
          message: "Nie wiadomo kto to powiedział?",
          type: "is-danger",
        });
        return;
      }
      var entry = {};
      entry.quote = this.msg
      entry.said = this.said
      this.$store.commit('setLoading', true)
      window.axios.post("api/quotes", entry).then(() => {
         this.$buefy.toast.open({
          message: "Wpis dodany, po akceptacji wyświetli się na stronie.",
          type: "is-success",
        });
        this.msg = ""
        this.said = ''
        this.$store.dispatch('refreshQuotes')
      });
    },
  },
};
</script>
<style>
.authorfield
{
}
.button {
  margin-left: 5px;
}
.field {
  align-items: center;
  justify-content: center !important;
}
.label {
  color: white !important;
}
.textinput input {
  background-color: black;
  color: white;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.labelquote {
  color: white !important;
}
section {
  width: 90%;
  margin: 0 auto;
}
.textinput {
  width: 60%;
  text-align: center;
}
b-field {
  margin: 0 auto;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
