<template>

  <div class="column childd" :class="{pendingChild: entry.visible==0}">
    <div class="" v-if="entry.visible==1">
      <span class="author">{{ entry.said }}</span><b-icon
                icon="comment-quote-outline"
                type="is-white"
                size="is-small">
            </b-icon>
    <div class="tile is-12 entry">{{ entry.quote }}</div>
    </div>

     <div class="pending" v-if="entry.visible==0">
      <span class="author">Soon</span><b-icon
                icon="comment-quote-outline"
                type="is-white"
                size="is-small">
            </b-icon>
    <div class="tile is-12 entry">Oczekuje...</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Entry",
  props: ["entry"],
  date() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pendingChild
{
  border-color:#1f1f1f;
}
.pending
{
  opacity:.2;
}
.entry
{
  font-family: 'Amatic SC';
  font-size:1.5rem;
}
.author
{
  margin-right:10px;
  font-weight:normal;
  font-size:14px;
  margin-bottom:15px;
}
.childd
{
   flex: 1 0 21%;
    border-style: double;
  border-radius:10px;
  margin: 10px  !important;
  padding: 20px;
  font-size:1.2rem;
  text-align:right;
}
</style>
