import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import axios from 'axios'
window.axios = axios
Vue.use(Buefy)
Vue.use(Vuex)
Vue.config.productionTip = false
const baseurl = 'https://amongowynotes.ga/'
//const BASE_URL = ''
const store = new Vuex.Store({
  state: () => ({
    quotes: [],
    quotesLoading: true,
  }),
  mutations: {
    setQuotes(state, quotes) {
      state.quotes = quotes
    },
    setLoading(state, loading)
    {
      state.quotesLoading = loading
    }
  },
  actions: {
    refreshQuotes(context) {
      window.axios.get(baseurl+'api/quotes').then(response => {
        context.commit('setQuotes', response.data.quotes)
        context.commit('setLoading', false)
      })
    }
  },
})
new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
