<template>
  <div class="columns is-multiline">
    <b-loading :is-full-page="true" v-model="quotesLoading" ></b-loading>
    <Entry v-for="entry in quotes" :key="entry.id" :entry="entry" ></Entry>
  </div>
</template>

<script>
import Entry from './Entry.vue'
import Vuex from 'vuex'
export default {
  name: 'Entries',
    components: {
      Entry
  },
  computed: {
    ...Vuex.mapState(["quotes", "quotesLoading"]),
  },
  methods:
  {
    ...Vuex.mapActions(["refreshQuotes"]),
  },
  mounted()
  {
    this.refreshQuotes();
  },
  data()
  {
    return{
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tile
{
    display: flex;
  flex-wrap: wrap;
}
h3 {
  margin: 40px 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
